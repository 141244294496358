import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import OrderDetails from "./OrderDetails";
import BillingDetails from "./BillingDetails";
import CustomButton from "../Button/CustomButton";
import ProductDetails from "./ProductDetails";
import Thankyou from "../Thankyou/Thankyou";
import useProductStore from "../../zustand/useProductStore";
import useOrderStore from "../../zustand/useOrderStore";
import PayPalComponent from "../PaPalComponent/PayPalComponent";
import useRevolutPayment from "../../hooks/useRevolutPayment"; // Adjust the path to your hook
import RevolutPaymentOverlay from "./PaymentOverlay";
import RevolutCheckout from "@revolut/checkout";
import RevolutComponent from "../RevolutComponent/RevolutComponent";

const Checkout = ({ setActiveStep, handleReset, handlePreviousStep }) => {
  const [showThankyou, setShowThankyou] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { properties } = useProductStore();
  const { orderDetails, setOrderId, setOrderToken } = useOrderStore();
  const [paymentOption, setPaymentOption] = useState("Apple Pay / Google Pay");
  const [total, setTotal] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: IsGPayOpen,
    onOpen: OnGPayOpen,
    onClose: OnGPayClose,
  } = useDisclosure();
  const {
    isOverlayVisible,
    startPayment,
    focusPopup,
    closePayment,
    paymentStatus,
  } = useRevolutPayment();
  const toast = useToast();
  const [checkoutInstance, setCheckoutInstance] = useState(null);

  document.getElementsByTagName("body")[0].style.overflow =
    isOverlayVisible || paymentStatus === null ? "hidden" : "auto";

  const validateFields = () => {
    const errors = {};
    const { name, contact, email, acceptTC } = orderDetails;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!name || name.trim() === "") errors.name = "Name is required.";
    if (!contact || !contact.trim() === "")
      errors.contact = "Contact Number is required.";
    if (!email || email.trim() === "") {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(email)) {
      errors.email = "Invalid Email Format.";
    }
    if (!acceptTC) errors.acceptTC = "Please accept Terms and Conditions.";

    return errors;
  };

  const deviceSupportsPopups = (ua) => {
    if (typeof ua === "undefined") {
      ua = navigator.userAgent;
    }

    // Helper functions
    const isIos = (ua) => /iPhone|iPod|iPad/.test(ua);
    const isAndroid = (ua) => /Android/.test(ua);
    const isOperaMini = (ua) => /Opera Mini/.test(ua);
    const isStandAlone = () =>
      window.matchMedia("(display-mode: standalone)").matches;

    // Checks for various devices and browsers
    return !(
      /(iPhone|iPod|iPad|Macintosh).*AppleWebKit(?!.*Safari)|.*WKWebView/i.test(
        ua
      ) ||
      /\bwv\b/.test(ua) ||
      /Android.*Version\/(\d)\.(\d)/i.test(ua) ||
      (isIos(ua) &&
        (/\bGSA\b/.test(ua) ||
          /.+AppleWebKit(?!.*Safari)|.*WKWebView/.test(ua))) ||
      (isAndroid(ua) && /Version\/[\d.]+/.test(ua) && !isOperaMini(ua)) ||
      isOperaMini(ua) ||
      /FxiOS/i.test(ua) ||
      /EdgiOS/i.test(ua) ||
      /FBAN/.test(ua) ||
      /FBAV/.test(ua) ||
      /QQBrowser/.test(ua) ||
      (typeof process !== "undefined" &&
        process.versions &&
        process.versions.electron) ||
      /Macintosh.*AppleWebKit(?!.*Safari)/i.test(ua) ||
      isStandAlone()
    );
  };

  const handlePlaceOrder = () => {
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach((error) => {
        toast({
          title: error,
          variant: "left-accent",
          status: "error",
          duration: 2000,
          position: "bottom-right",
        });
      });
      return;
    }

    setIsLoading(true);
    const orderData = {
      ...orderDetails,
      orderData: properties,
      paymentMethod: paymentOption,
    };
    // if (
    //   (paymentOption === "PayPal" && !orderDetails.orderId) ||
    //   ((paymentOption === "Revolut" || paymentOption === "Apple Pay / Google Pay") &&
    //     !orderDetails.orderToken)
    // ) {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderData),
    })
      .then((res) => res.json())
      .catch((error) => {
        // windowReference?.close();
        setIsLoading(false);
        console.error("Failed to place order.", error);
        toast({
          title: "Failed to place order.",
          variant: "left-accent",
          status: "error",
          duration: 2000,
          position: "bottom-right",
        });
      })
      .then((data) => {
        if (data.error) {
          toast({
            title: data.error,
            variant: "left-accent",
            status: "error",
            duration: 2000,
            position: "bottom-right",
          });
          setIsLoading(false);
        } else {
          if (data.order_id) {
            setOrderId(data.order_id);
          }
          if (data.token) {
            setOrderToken(data.token);
          }
          if (paymentOption === "PayPal") {
            onOpen();
          } else {
            // handleRevolutPayment(data, windowReference);
            if (data.token) {
              handleRevolutPayment(data.token, paymentOption);
            } else {
              console.log("No token received");
              toast({
                title: "Failed to place order.",
                variant: "left-accent",
                status: "error",
                duration: 2000,
                position: "bottom-right",
              });
            }
          }
        }
      })
      .catch((error) => {
        // windowReference?.close();
        setIsLoading(false);
        console.error("Failed to place order.", error);
        toast({
          title: "Failed to place order.",
          variant: "left-accent",
          status: "error",
          duration: 2000,
          position: "bottom-right",
        });
      });
    // } else {
    //   if (paymentOption === "PayPal") {
    //     onOpen();
    //   } else {
    //     handleRevolutPayment(orderDetails.orderToken, paymentOption);
    //   }
    // }
  };

  // const handleRevolutPayment = (data, windowReference) => {
  //   setIsLoading(false);
  //   if (data?.checkout_url) {
  //     startPayment(
  //       data,
  //       windowReference,
  //       handlePaymentSuccess,
  //       handlePaymentError
  //     );
  //   }
  // };

  const handleRevolutPayment = async (token, paymentOption) => {
    const checkout = await RevolutCheckout(token);
    setCheckoutInstance(checkout);
    if (token) {
      if (paymentOption === "Revolut Card") {
        setIsLoading(false);
        checkout.payWithPopup({
          onSuccess() {
            handlePaymentSuccess(null, "Payment Completed Successfully");
          },
          onError(message) {
            handlePaymentError(message);
            console.log(message);
          },
        });
      } else if (paymentOption === "Revolut Bank") {
        setIsLoading(false);
        checkout.openBanking({
          onSuccess() {
            handlePaymentSuccess(null, "Payment Completed Successfully");
          },
          onError(message) {
            handlePaymentError(message);
            console.log(message);
          },
        });
      } else {
        if (checkout) {
          OnGPayOpen();
        }
      }
    } else {
      console.log("No token received");
      toast({
        title: "Failed to place order.",
        variant: "left-accent",
        status: "error",
        duration: 2000,
        position: "bottom-right",
      });
    }
  };

  const handlePaymentSuccess = (details, msg = null) => {
    toast({
      title: "Payment Successful",
      description: msg
        ? msg
        : `Transaction completed by ${details.payer.name.given_name}`,
      status: "success",
      duration: 2000,
      position: "bottom-right",
      containerStyle: { color: "white" },
    });
    setShowThankyou(true);
    setTimeout(() => {
      setShowThankyou(false);
      handleReset();
    }, 1500);
  };

  const handlePaymentError = (error) => {
    console.error("Payment Error:", error);
    toast({
      title: "Payment Failed",
      description: "An error occurred during the payment process.",
      status: "error",
      duration: 2000,
      position: "bottom-right",
      containerStyle: { color: "white" },
    });
  };

  return (
    <Container maxW={"7xl"}>
      <Grid
        templateColumns={{ base: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" }}
        gap={10}
        my={6}
      >
        <Box>
          <OrderDetails />
        </Box>

        <Box>
          <BillingDetails onPaymentOptionChange={setPaymentOption} />
        </Box>

        <Box
          gridColumn={{ base: "1", sm: "span 2", md: "auto" }}
          maxW={"md"}
          mx={"auto"}
        >
          <ProductDetails
            setActiveStep={setActiveStep}
            setDiscountedTotal={setTotal}
          />
        </Box>
      </Grid>

      <Box
        display={"flex"}
        alignItems={"center"}
        w={"100%"}
        mb={20}
        flexDirection={"column"}
        gap={4}
      >
        <CustomButton
          text={"Place Order"}
          bgColor={"green.1000"}
          width={"180px"}
          onClick={handlePlaceOrder}
          loading={isLoading}
        />

        <CustomButton
          text={"Back"}
          onClick={handlePreviousStep}
          width={"180px"}
        />
      </Box>

      <RevolutComponent
        checkoutInstance={checkoutInstance}
        handlePaymentError={handlePaymentError}
        handlePaymentSuccess={handlePaymentSuccess}
        IsGPayOpen={IsGPayOpen}
        OnGPayClose={OnGPayClose}
        isLoading={isLoading}
        setLoading={setIsLoading}
      />

      <Box display={"none"}>
        <PayPalComponent
          orderId={orderDetails.orderId}
          amount={total}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentError={handlePaymentError}
          isOpen={isOpen}
          onClose={onClose}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      </Box>

      {showThankyou && <Thankyou />}

      {/* Optional Overlay for Revolut */}
      {(isOverlayVisible || paymentStatus === null) && (
        <RevolutPaymentOverlay
          focusPopup={focusPopup}
          closePayment={closePayment}
          isOverlayVisible={isOverlayVisible}
          paymentStatus={paymentStatus}
        />
      )}
    </Container>
  );
};

export default Checkout;
