import React, { useCallback, useEffect, useState } from "react";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { components } from "react-select";
import { IoMdArrowDropdown } from "react-icons/io";
import useProductStore from "../../zustand/useProductStore";

const TypeOfProperty = [
  { value: "House", label: "House" },
  { value: "Flat", label: "Flat" },
  { value: "Room", label: "Room" },
  { value: "Commercial Property", label: "Commercial Property" },
];

const PropertyHouseSize = [
  { value: "Studio", label: "Studio" },
  { value: "1 Bed House", label: "1 Bed House" },
  { value: "2 Bed House", label: "2 Bed House" },
  { value: "3 Bed House", label: "3 Bed House" },
  { value: "4 Bed House", label: "4 Bed House" },
  { value: "5 Bed House", label: "5 Bed House" },
  { value: "6+ Bed House", label: "6+ Bed House" },
];

const PropertyFlatSize = [
  { value: "1 Bed Flat", label: "1 Bed Flat" },
  { value: "2 Bed Flat", label: "2 Bed Flat" },
  { value: "3 Bed Flat", label: "3 Bed Flat" },
  { value: "4 Bed Flat", label: "4 Bed Flat" },
  { value: "5 Bed Flat", label: "5 Bed Flat" },
  { value: "6+ Bed Flat", label: "6+ Bed Flat" },
];

const PropertyRoomSize = [
  { value: "1 Room", label: "1 Room" },
  { value: "2 Room", label: "2 Room" },
  { value: "3 Room", label: "3 Room" },
  { value: "4 Room", label: "4 Room" },
  { value: "5 Room", label: "5 Room" },
  { value: "6+ Room", label: "6+ Room" },
];

const CommercialPropertySize = [
  { value: "Small - Up to 1’200 sq. ft", label: "Small - Up to 1’200 sq. ft" },
  {
    value: "Medium - Up to 5’000 sq. ft",
    label: "Medium - Up to 5’000 sq. ft",
  },
  { value: "Large - Up to 10’000 sq.ft", label: "Large - Up to 10’000 sq.ft" },
  {
    value: "Extra Large - More than 10'000 sq.ft",
    label: "Extra Large - More than 10'000 sq.ft",
  },
];

const AccessProperty = [
  { value: "I Will Provide Access", label: "I Will Provide Access" },
  { value: "Keys In Office", label: "Keys In Office" },
  { value: "Vendor/Tenant/Agent", label: "Vendor/Tenant/Agent" },
  { value: "Concierge/Porter", label: "Concierge/Porter" },
  { value: "Keybox", label: "Keybox" },
  { value: "Other", label: "Other" },
];

const customStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "inherit" : baseStyles.borderColor,
    boxShadow: state.isFocused ? "none" : baseStyles.boxShadow,
    outline: state.isFocused ? "none" : baseStyles.outline,
    "&:hover": {
      borderColor: "inherit",
      outline: "none !important",
      boxShadow: "none",
    },
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "white",
  }),
  option: (baseStyles, { isSelected }) => ({
    ...baseStyles,
    backgroundColor: isSelected ? "#9BFF37" : baseStyles.backgroundColor,
    color: isSelected ? "black" : baseStyles.color,
    "&:hover": {
      backgroundColor: isSelected ? "#9BFF37" : "#e4ffc9",
    },
  }),
};

const customTheme = (theme) => ({
  ...theme,
  outline: "none",
  colors: {
    ...theme.colors,
    primary25: "#e4ffc9",
    primary: "#9BFF37",
  },
});

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <IoMdArrowDropdown style={{ color: "#7d7d7d", fontSize: "20px" }} />
    </components.DropdownIndicator>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      {props.selectProps.inputValue ? "No Address Found!" : "Search Address"}
    </components.NoOptionsMessage>
  );
};

const debounce = (fn, delay = 250) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

const PropertyDetails = () => {
  const { propertyDetails, setPropertyDetails } = useProductStore();
  const [addressOptions, setAddressOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleInputChange = (field, value) => {
    setPropertyDetails({ ...propertyDetails, [field]: value });
  };

  const handlePropertyTypeChange = (selectedOption) => {
    setPropertyDetails({
      ...propertyDetails,
      propertyType: selectedOption,
      propertySize: null, // Reset property size when property type changes
    });
  };

  const fetchAddressSuggestions = async (inputValue) => {
    if (!inputValue) return;
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/address?input=${inputValue}`
      );
      const data = await response.json();
      setIsLoading(false);
      return (
        data?.suggestions?.map((suggestion) => ({
          label: suggestion.address,
          value: suggestion.address,
        })) || []
      );
    } catch (error) {
      toast({
        title: "Error fetching addresses",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
      return [];
    }
  };

  const loadOptionsDebounced = debounce((inputValue, callback) => {
    fetchAddressSuggestions(inputValue).then((options) => {
      setAddressOptions(options);
      callback(options);
    });
  }, 500);
  const ref = React.useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.inputRef?.setAttribute("inputmode", "search");
      ref.current.inputRef?.setAttribute("autoComplete", "off");
      ref.current.inputRef?.setAttribute("autoCorrect", "off");
      ref.current.inputRef?.setAttribute("autoCapitalize", "off");
      ref.current.inputRef?.setAttribute("spellCheck", "false");
      ref.current.inputRef?.addEventListener("focusout", (e) => {
        console.log("focusout");
        e.preventDefault();
      });
    }
  }, [ref]);

  return (
    <Flex flexDirection={"column"} gap={4} maxW={"md"} mx="auto">
      <FormControl isRequired>
        <FormLabel variant={"label"}>
          {" "}
          Address: Start typing PostCode (format SE1 7PM){" "}
        </FormLabel>
        <AsyncSelect
          defaultOptions={addressOptions}
          loadOptions={loadOptionsDebounced}
          isLoading={isLoading}
          placeholder="Start typing"
          styles={customStyles}
          theme={customTheme}
          ref={ref}
          onBlur={(e) => {
            e.preventDefault();
          }}
          components={{
            DropdownIndicator,
            IndicatorSeparator: null,
            NoOptionsMessage,
          }}
          onChange={(selectedOption) =>
            handleInputChange("address", selectedOption.value)
          }
          value={
            propertyDetails.address === ""
              ? null
              : {
                  label: propertyDetails.address,
                  value: propertyDetails.address,
                }
          }
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel variant={"label"}> Property type </FormLabel>
        <Select
          options={TypeOfProperty}
          placeholder="Choose an option"
          styles={customStyles}
          theme={customTheme}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          onChange={handlePropertyTypeChange}
          value={propertyDetails.propertyType}
        />
      </FormControl>

      {propertyDetails.propertyType && propertyDetails.propertyType !== "" && (
        <FormControl isRequired>
          <FormLabel variant={"label"}> Property size </FormLabel>
          <Select
            options={
              propertyDetails.propertyType.value === "Commercial Property"
                ? CommercialPropertySize
                : propertyDetails.propertyType.value === "House"
                ? PropertyHouseSize
                : propertyDetails.propertyType.value === "Flat"
                ? PropertyFlatSize
                : propertyDetails.propertyType.value === "Room"
                ? PropertyRoomSize
                : null
            }
            placeholder="Choose an option"
            styles={customStyles}
            theme={customTheme}
            components={{ DropdownIndicator, IndicatorSeparator: null }}
            onChange={(selectedOption) =>
              handleInputChange("propertySize", selectedOption)
            }
            value={propertyDetails.propertySize}
          />
        </FormControl>
      )}

      <FormControl isRequired>
        <FormLabel variant={"label"}> Access to property </FormLabel>
        <Select
          options={AccessProperty}
          placeholder="Choose an option"
          styles={customStyles}
          theme={customTheme}
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          onChange={(selectedOption) =>
            handleInputChange("access", selectedOption)
          }
          value={propertyDetails.access}
        />
      </FormControl>

      {propertyDetails.access?.value === "Keys In Office" && (
        <>
          <FormControl isRequired>
            <FormLabel variant={"label"}> Office Name </FormLabel>
            <Input
              type="text"
              placeholder="Enter Office Name"
              value={propertyDetails.officeName}
              onChange={(e) => handleInputChange("officeName", e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel variant={"label"}> Office Address </FormLabel>
            <Input
              type="text"
              placeholder="Enter Office Address"
              value={propertyDetails.officeAddress}
              onChange={(e) =>
                handleInputChange("officeAddress", e.target.value)
              }
            />
          </FormControl>
        </>
      )}

      {propertyDetails.access?.value === "Vendor/Tenant/Agent" && (
        <>
          <FormControl isRequired>
            <FormLabel variant={"label"}> Contact Name </FormLabel>
            <Input
              type="text"
              placeholder="Enter Contact Name"
              value={propertyDetails.tenantName}
              onChange={(e) => handleInputChange("tenantName", e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel variant={"label"}> Contact Number </FormLabel>
            <Input
              type="number"
              placeholder="Enter Contact Number"
              value={propertyDetails.tenantNumber}
              onChange={(e) =>
                handleInputChange("tenantNumber", e.target.value)
              }
            />
          </FormControl>
        </>
      )}

      {propertyDetails.access?.value === "Keybox" && (
        <>
          <FormControl isRequired>
            <FormLabel variant={"label"}> Code </FormLabel>
            <Input
              type="text"
              placeholder="Enter Code"
              value={propertyDetails.boxCode}
              onChange={(e) => handleInputChange("boxCode", e.target.value)}
            />
          </FormControl>
        </>
      )}

      <FormControl>
        <FormLabel variant={"label"}> Notes </FormLabel>
        <Textarea
          placeholder="Your comments"
          resize={"none"}
          value={propertyDetails.comments}
          onChange={(e) => handleInputChange("comments", e.target.value)}
        />
      </FormControl>
    </Flex>
  );
};

export default PropertyDetails;
