// import { useToast } from "@chakra-ui/react";
// import { useEffect, useState } from "react";

// const useRevolutPayment = () => {
//     const [isOverlayVisible, setIsOverlayVisible] = useState(false);
//     const [paymentPopup, setPaymentPopup] = useState(null);
//     const [paymentStatus, setPaymentStatus] = useState(undefined);
//     const toast = useToast();

//     useEffect(() => {
//         const handleMessage = (event) => {
//             if (event.data === 'payment-success') {
//                 if (paymentPopup && !paymentPopup.closed) {
//                     paymentPopup.close();
//                 }
//             }
//         };

//         window.addEventListener('message', handleMessage);

//         return () => {
//             window.removeEventListener('message', handleMessage);
//         };
//     }, [paymentPopup, toast]);

//     const checkPaymentStatus = async (token, handleSuccess, handleError) => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment/status`, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify({ token }),
//             });
//             const data = await response.json();
//             const status = {
//                 success: true,
//                 completed: true,
//                 pending: false,
//                 failure: false,
//                 failed: false,
//             };
//             const paymentStatus = (status[data.orderStatus] || status[data.revolutStatus]) ? true : false;
//             setPaymentStatus(paymentStatus ? true : false);
//             if (paymentStatus) {
//                 handleSuccess(null, "Payment Completed Successfully.");
//             } else {
//                 handleError();
//             }
//         } catch (error) {
//             console.error("Failed to check payment status.", error);
//             setPaymentStatus(false);
//             handleError();
//         }
//     }

//     const startPayment = (data, handleSuccess, handleError) => {
//         const { checkout_url, token } = data;
//         if (!checkout_url) {
//             console.error("Checkout URL is required to start payment.");
//             return;
//         }

//         const left = window.screen.width / 2 - 500 / 2;
//         const top = window.screen.height / 2 - 700 / 2;
//         const popup = window.open(
//             checkout_url,
//             "revolut-payment",
//             `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=500, height=700, top=${top}, left=${left}`
//         );

//         setPaymentPopup(popup);
//         setIsOverlayVisible(true);
//         setPaymentStatus(null);

//         const popupInterval = setInterval(() => {
//             if (popup.closed) {
//                 clearInterval(popupInterval);
//                 setIsOverlayVisible(false);
//                 console.log("Payment popup closed.");
//                 checkPaymentStatus(token, handleSuccess, handleError);
//             }
//         }, 500);
//     };

//     const focusPopup = () => {
//         if (paymentPopup && !paymentPopup.closed) {
//             paymentPopup.focus();
//         }
//     };

//     const closePayment = () => {
//         if (paymentPopup) {
//             paymentPopup.close();
//         }
//         setIsOverlayVisible(false);
//     };

//     return {
//         isOverlayVisible,
//         startPayment,
//         focusPopup,
//         closePayment,
//         paymentStatus,
//     };
// };

// export default useRevolutPayment;





import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const useRevolutPayment = () => {
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [paymentPopup, setPaymentPopup] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('pending');
    const toast = useToast();

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data === 'payment-success') {
                if (paymentPopup && !paymentPopup.closed) {
                    paymentPopup.close();
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
            if (paymentPopup && !paymentPopup.closed) {
                paymentPopup.close();
            }
        };
    }, [paymentPopup]);

    const checkPaymentStatus = async (token, handleSuccess, handleError) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/payment/status`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token }),
            });
            const data = await response.json();
            const status = {
                success: true,
                completed: true,
                pending: false,
                failure: false,
                failed: false,
            };
            const paymentStatus = status[data.orderStatus] || status[data.revolutStatus] || false;
            setPaymentStatus(paymentStatus);
            if (paymentStatus) {
                handleSuccess(null, "Payment Completed Successfully.");
            } else {
                handleError();
            }
        } catch (error) {
            console.error("Failed to check payment status.", error);
            setPaymentStatus(false);
            handleError();
        }
    };

    const startPayment = (data, windowReference, handleSuccess, handleError) => {
        const { checkout_url, token } = data;
        if (!checkout_url) {
            console.error("Checkout URL is required to start payment.");
            handleError(new Error("Checkout URL is missing."));
            return;
        }


        const popup = windowReference;
        // window.open(
        //     checkout_url,
        //     "revolut-payment",
        //     `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=500, height=700, top=${top}, left=${left}`
        // );
        popup.location = checkout_url;

        if (!popup) {
            console.error("Failed to open the popup window.");
            handleError(new Error("Failed to open payment window."));
            return;
        }

        setPaymentPopup(popup);
        setIsOverlayVisible(true);
        setPaymentStatus('pending');

        const popupInterval = setInterval(() => {
            if (popup.closed) {
                clearInterval(popupInterval);
                setIsOverlayVisible(false);
                // console.log("Payment popup closed.");
                checkPaymentStatus(token, handleSuccess, handleError);
            }
        }, 500);

        return () => clearInterval(popupInterval);
    };

    const isAppleDevice = () => /iPhone|iPod|iPad/.test(navigator.userAgent);

    const focusPopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (paymentPopup && !paymentPopup.closed) {
            if (isAppleDevice()) {
                alert("Please switch tabs to reactivate the Revolut window.");
            } else {
                paymentPopup.focus();
            }
        }
    };

    const closePayment = () => {
        if (paymentPopup) {
            paymentPopup.close();
        }
        setIsOverlayVisible(false);
    };

    return {
        isOverlayVisible,
        startPayment,
        focusPopup,
        closePayment,
        paymentStatus,
    };
};

export default useRevolutPayment;
