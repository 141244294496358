import { create } from "zustand";

const initialState = {
  // Define your initial state here
  products: [],
  bundles: [],
  addOns: [],
};

const useDataStore = create((set) => ({
  // Define your state variables here
  ...initialState,

  // Define your state update functions here
  setProducts: (products) => set({ products }),
  setBundles: (bundles) => set({ bundles }),
  setAddOns: (addOns) => set({ addOns }),
}));

export default useDataStore;
