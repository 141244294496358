import { Button } from '@chakra-ui/react'
import React from 'react'

const CustomButton = ({ text, width, maxWidth, color, bgColor, onClick, loading = false }) => {
  return (
    <Button
      background={bgColor}
      width={width}
      maxWidth={maxWidth}
      color={color}
      fontSize={'14px'}
      fontWeight={600}
      onClick={onClick}
      _hover={{ bg: bgColor }}
      isLoading={loading}
    >
      {text}
    </Button>
  )
}

export default CustomButton