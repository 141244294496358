import {
  Box,
  Flex,
  Text,
  IconButton,
  Image,
  Link,
  Spinner,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import revolutLogo from "../../assets/images/revolut.svg";

const RevolutPaymentOverlay = ({
  focusPopup,
  closePayment,
  paymentStatus,
  isOverlayVisible,
}) => {
  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      bg="rgba(0, 0, 0, 0.898)"
      justifyContent="center"
      alignItems="center"
      zIndex="1000"
      fontSize={"15px"}
      onClick={focusPopup}
      cursor={"pointer"}
      fontFamily={`"HelveticaNeue",
        "HelveticaNeue-Light",
        "Helvetica Neue Light",
        helvetica,
        arial,
        sans-serif`}
    >
      <Box
        maxW={"350px"}
        fontSize={"14px"}
        textAlign="center"
        position="relative"
        width="400px"
      >
        <IconButton
          icon={<CloseIcon />}
          position="fixed"
          color={"#A4A4A4"}
          top="10px"
          right="10px"
          bg="none"
          border="none"
          _hover={{ bg: "none", color: "white", animation: "none" }}
          animation={"none"}
          fontSize="12px"
          fontWeight={"900"}
          cursor="pointer"
          onClick={closePayment}
          aria-label="Close"
        />
        <Image
          src={revolutLogo}
          alt="Revolut Logo"
          mb={isOverlayVisible ? 0 : "-15px"}
          mx="auto"
          width="120px"
        />
        {isOverlayVisible ? (
          <Box>
            <Text lineHeight={"1.5"} fontSize={"15px"} color="white" mb={4}>
              Don’t see the secure Revolut browser? We’ll help you re-launch the
              window to complete your purchase
            </Text>

            <Link
              width="full"
              onClick={focusPopup}
              mt={4}
              textDecor={"none"}
              _hover={{ textDecor: "none" }}
              color={"white"}
              fontWeight={"bolder"}
              borderBottom={"1px solid white"}
            >
              Click To Continue
            </Link>
          </Box>
        ) : (
          <Box>
            <Text color={"white"} fontSize={"15px"}>
              Processing Your Payment, Please Wait...
            </Text>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              mt={4}
            >
              <Spinner color="white" size="lg" />
            </Box>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default RevolutPaymentOverlay;
