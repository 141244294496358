import React from 'react'
import { Container, Heading } from '@chakra-ui/react'
import { StepComponent } from './StepComponent'

const Stepper = ({ activeStep, handleNextStep, setActiveStep }) => {

  const handleStepClick = (stepIndex) => {
    if (stepIndex < activeStep) {
      setActiveStep(stepIndex);
    }
  };
    
  return (
    <Container maxW='7xl' py={2}>
        <Heading variant='h1' textAlign='center' ml={{base:0, md:12}}>
          {activeStep === 0 ? 'New Booking' : 
          activeStep === 1 ? 'Details' : 'Checkout'}
        </Heading>
        <Container maxW='3xl' py={2} mt={4}>
          <StepComponent activeStep={activeStep} handleNextStep={handleNextStep} handleStepClick={handleStepClick} />
        </Container>
    </Container>
  )
}

export default Stepper