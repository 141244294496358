import useProductStore from "../../zustand/useProductStore";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

// helpers
const dateAlreadyClicked = (dates, date) =>
  dates.some((d) => dayjs(date).isSame(dayjs(d), "day"));
const datesExcept = (dates, date) =>
  dates.filter((d) => !dayjs(date).isSame(dayjs(d), "day"));

const Calendar = ({ classes, ...props }) => {
  const { propertyDetails, setPropertyDetails } = useProductStore();
  const [dates, setDates] = useState(propertyDetails?.date || []);

  useEffect(() => {
    setPropertyDetails({ date: dates });
  }, [dates]);

  const onClickDay = (date) => {
    date = dayjs(date).format("YYYY-MM-DD");
    if (dateAlreadyClicked(dates, date)) {
      setDates(datesExcept(dates, date));
    } else {
      setDates([...dates, date]);
    }
  };

  const tileClassName = ({ date, view }) => {
    const classNames = [classes.dayTile];
    if (dateAlreadyClicked(dates, date)) classNames.push(classes.activeDay);

    if (view === "month") {
      const day = date.getDay();
      if (day === 0 || day === 6) {
        classNames.push("calendar-day-weekend");
      }
    }

    return classNames.join(" ");
  };

  return (
    <ReactCalendar
      tileClassName={tileClassName}
      onClickDay={onClickDay}
      {...props}
      value={null}
    />
  );
};

export default Calendar;
