import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Send a message to the parent window (the main application)
        if (window.opener) {
            window.opener.postMessage('payment-success', '*');

            // Close the popup window
            window.close();
        } else {
            // If the window cannot be closed (e.g., opened in a new tab), redirect to the main page
            navigate('/');
        }
    }, [navigate]);

    return (
        <div>
            <h1>Payment Successful</h1>
            <p>Your payment has been processed successfully. You will be redirected shortly.</p>
        </div>
    );
};

export default PaymentSuccess;
