import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { theme } from "../src/theme/theme";
import './App.css';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { Home } from './pages/Home/Home';
import PaymentSuccess from './pages/payment-success/PaymentSuccess';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
          </Routes>
        </Router>
      </Box>
    </ChakraProvider>
  );
}

export default App;
