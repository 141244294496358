import useOrderStore from "../../zustand/useOrderStore";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import React from "react";

const OrderDetails = () => {
  const { orderDetails, setOrderDetails } = useOrderStore();

  const handleInputChange = (field, value) => {
    setOrderDetails({ ...orderDetails, [field]: value });
  };

  return (
    <Flex flexDirection={"column"} gap={4}>
      <Heading as="h3" textAlign={"center"} variant={"h3"}>
        Order Details
      </Heading>

      <FormControl isRequired>
        <FormLabel variant={"label"}>Name</FormLabel>
        <Input
          type="text"
          value={orderDetails.name}
          onChange={(e) => handleInputChange("name", e.target.value)}
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel variant={"label"}>Contact Number</FormLabel>
        <Input
          type="number"
          value={orderDetails.contact}
          onChange={(e) => handleInputChange("contact", e.target.value)}
        />
      </FormControl>

      <FormControl isRequired>
        <FormLabel variant={"label"}>Email Address</FormLabel>
        <Input
          type="email"
          value={orderDetails.email}
          onChange={(e) => handleInputChange("email", e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel variant={"label"}>Company Name (Optional)</FormLabel>
        <Input
          type="text"
          value={orderDetails.companyName}
          onChange={(e) => handleInputChange("companyName", e.target.value)}
        />
      </FormControl>
    </Flex>
  );
};

export default OrderDetails;
