import React from 'react'
import { Image, Container, Heading, Box, Text, Grid, Button, Flex, useMediaQuery } from '@chakra-ui/react'
import question from '../../assets/images/ep_question-filled.png'
import DescriptionModal from '../DescriptionModal/DescriptionModal';

const ProductLayout = ({
    productData,
    heading,
    paddingTop,
    paddingBottom,
    imageWidth,
    selectedServices,
    handleClick,
    handleButtonClick,
    selectedServicesTypes,
    selectedImage,
    selectedDescription,
    selectedButtonServiceID,
    minH,
    minTextHeight,
    maxH
}) => {

    const [isLargerThanMd] = useMediaQuery("(min-width: 768px)");


    return (
        <Container maxW='7xl' py={2}>
            <Heading variant='h3' mb={6}>{heading}</Heading>
            <Flex justifyContent='center' flexWrap={'wrap'} gap={3}>

                {productData.map((data, index) => {
                    console.log()
                    return (
                        <Box key={index} onClick={() => handleClick(data.id)}
                            background={
                                selectedServices?.includes(data?.id) ? 'green.900' : 'initial'
                            }
                            border='1px'
                            borderColor={selectedServices?.includes(data?.id) ? 'green.400' : 'gray.100'}
                            borderRadius='10px'
                            position='relative'
                            overflow='hidden'
                            transition=".5s"
                            _hover={{ boxShadow: { base: 'none', md: 'md' } }}
                            width={{ lg: '19%', md: '31%', base: '48%' }}
                            maxW={{ lg: '19%', md: '31%', base: '48%' }}
                            maxH={maxH ?? 'max-content'}
                        >
                            <Flex flexDirection='column' justifyContent={data.button ? 'space-between' : 'center'} height='100%' alignItems='center' minH={minH ?? 'max-content'}>
                                <Box pt={paddingTop} pb={paddingBottom} >
                                    <DescriptionModal description={data.button?.find(e => e.id == [selectedServicesTypes?.[data?.id]])?.description ?? data.description} />
                                    <Image src={selectedButtonServiceID === data.id ? selectedImage : data.image} alt='services image' style={{ margin: 'auto' }} pb={2} width={imageWidth ?? 'auto'} />
                                    <Text variant='custom-para' textAlign='center' minH={minTextHeight ?? "max-content"}>{data.heading}</Text>
                                </Box>
                                <Flex justifyContent={'space-between'} width={'100%'} borderColor={'#BEEF8E'} borderTopWidth={data.button ? '2px' : '0'} border={'2px 0 0 0'} >
                                    {data.button && data.button.map((x, i) => {
                                        return (
                                            <Button variant='solid-btn' background={selectedServicesTypes[data?.id] === x.id ?? i ? 'green.900' : '#fff'} onClick={(e) => {
                                                handleButtonClick(e, data?.id, i, x);
                                            }} key={i}>{x?.heading ?? x}</Button>
                                        )
                                    })}
                                </Flex>
                            </Flex>
                        </Box>
                    )
                })}
            </Flex>
        </Container>
    )
}

export default ProductLayout