import React from 'react'
import { Image, Button, Container, Flex, Box } from '@chakra-ui/react'
import logo from '../../assets/images/logo.png'
import { Link } from '@chakra-ui/react'
import MenuBar from './MenuBar'

const Navbar = () => {
  return (
    <Container maxW='7xl' py={2}>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Link href='https://www.spacephoto.co.uk'>
          <Image src={logo} alt='logo' />
        </Link>
        <Flex gap={6} display={{ base: 'none', md: 'flex' }}>
          <Link variant={'black-link'} href='https://www.spacephoto.co.uk/about-space-photo/'>About Us</Link>
          <Link variant={'black-link'} href='https://www.spacephoto.co.uk/products-services/'>Products And Services</Link>
          <Link variant={'black-link'} href='https://www.spacephoto.co.uk/portfolios/' >Portfolio</Link>
          <Link variant={'black-link'} href='/'>Shop</Link>
          <Link variant={'black-link'} href='https://www.spacephoto.co.uk/blog/'>Blog</Link>
          <Link variant={'black-link'} href='https://www.spacephoto.co.uk/contact-us/'>Contact Us</Link>
        </Flex>
        {/* <Button variant={"solid-green-btn"} display={{ base: 'none', md: 'block' }} fontWeight={600}>Book Now</Button> */}
        <Box display={{ base: 'none', md: 'block' }}></Box>
        <Box display={{ base: 'block', md: 'none' }}>
          <MenuBar />
        </Box>
      </Flex>
    </Container>
  )
}

export default Navbar