import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  Spinner,
  ModalFooter,
  Image,
} from "@chakra-ui/react";
import revolutLogo from "../../assets/images/revolut.svg";

const RevolutComponent = ({
  IsGPayOpen,
  OnGPayClose,
  isLoading,
  setLoading,
  handlePaymentSuccess,
  handlePaymentError,
  checkoutInstance,
}) => {
  const ref = React.useCallback(
    (node) => {
      const handlePayment = async () => {
        if (
          IsGPayOpen &&
          checkoutInstance &&
          node &&
          node.childNodes.length === 0
        ) {
          const paymentRequest = checkoutInstance.paymentRequest({
            buttonStyle: {
              radius: "small",
              height: "45px",
            },
            onSuccess() {
              handlePaymentSuccess(null, "Payment Completed Successfully");
            },
            onError(message) {
              handlePaymentError(message);
              console.log(message);
            },
            target: node,
          });
          if (paymentRequest) {
            const canMakePayment = await paymentRequest.canMakePayment();
            if (canMakePayment) {
              paymentRequest.render();
            } else {
              OnGPayClose();
              setLoading(false);
              handlePaymentError("Cannot make payment");
            }
          }
          setLoading(false);
        }
      };
      handlePayment();
    },
    [
      IsGPayOpen,
      OnGPayClose,
      checkoutInstance,
      handlePaymentError,
      handlePaymentSuccess,
      setLoading,
    ]
  );

  return (
    <Modal
      isOpen={IsGPayOpen}
      onClose={() => {
        OnGPayClose();
        setLoading(false);
      }}
      isCentered
    >
      <Box display={isLoading ? "none" : "block"}>
        <ModalOverlay />
        <ModalContent bg={"white"}>
          <ModalCloseButton />
          <ModalBody mt={10}>
            <Box ref={ref} id="revolut-apple-pay" />
          </ModalBody>
          <ModalFooter alignContent={"center"} justifyContent={"center"}>
            <Box
              textAlign={"center"}
              fontSize={"11px"}
              fontFamily={'Helvetica, Arial, "Liberation Sans", sans-serif'}
              color={"#7b8388"}
              fontStyle={"italic"}
              fontWeight={"bold"}
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
            >
              Powered by <Image src={revolutLogo} height={10} />
            </Box>
          </ModalFooter>
        </ModalContent>
      </Box>

      <Box display={isLoading ? "block" : "none"}>
        <ModalOverlay />
        <ModalContent bg={"white"}>
          <ModalCloseButton />
          <ModalBody m={8}>
            <Box textAlign={"center"}>
              <Box>
                <Spinner thickness="3px" />
              </Box>
              Loading...
            </Box>
          </ModalBody>
        </ModalContent>
      </Box>
    </Modal>
  );
};

export default RevolutComponent;
