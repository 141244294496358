// StepComponent.jsx
import React from 'react';
import { Box, Step, StepIndicator, StepSeparator, StepStatus, Stepper } from '@chakra-ui/react';
import { IoMdCheckmark } from 'react-icons/io';

const steps = [
  { title: 'Select services' },
  { title: 'Order details' },
  { title: 'Finish' },
];

export function StepComponent({ activeStep, handleStepClick  }) {
  


  return (
    <>
      <Stepper index={activeStep} paddingLeft={'30px'} paddingRight={'6px'}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator onClick={() => handleStepClick(index)} cursor={'pointer'}>
              <StepStatus
                active={<IoMdCheckmark color='#fff' />}
                // incomplete={<StepNumber />}
                complete={<IoMdCheckmark color='#fff' />}
              />
            </StepIndicator>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      <Box w={'100%'} display={'flex'} justifyContent={'space-between'} mt={1}>
        {steps.map((step, index) => (
          <Box
            style={{ fontWeight: '600', color: '#000' }}
            fontSize={{ base: '14px', md: 'inherit' }}
            key={index}
            paddingRight={index === 1 ? '35px' : '0'}
          >
            {step.title}
          </Box>
        ))}
      </Box>
    </>
  );
}
