import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  Link,
} from '@chakra-ui/react'
import React from 'react'
import { RxHamburgerMenu } from "react-icons/rx";


const MenuBar = () => {
  return (
    <Menu>
      <MenuButton as={Button} background={'transparent'}>
        <RxHamburgerMenu color='#000' />
      </MenuButton>
      <MenuList color={'black.100'} border={'none'}>
        <MenuItem as={Link} _hover={{ textDecor: 'none' }} href='https://www.spacephoto.co.uk/about-space-photo/'>About Us</MenuItem>
        <MenuItem as={Link} _hover={{ textDecor: 'none' }} href='https://www.spacephoto.co.uk/products-services/'>
          Products And Services
        </MenuItem>
        <MenuItem as={Link} _hover={{ textDecor: 'none' }} href='https://www.spacephoto.co.uk/portfolios/'>Portfolio</MenuItem>
        <MenuItem as={Link} _hover={{ textDecor: 'none' }} href='/'>Shop</MenuItem>
        <MenuItem as={Link} _hover={{ textDecor: 'none' }} href='https://www.spacephoto.co.uk/blog/'>Blog</MenuItem>
        <MenuItem as={Link} _hover={{ textDecor: 'none' }} href='https://www.spacephoto.co.uk/contact-us/'>Contact Us</MenuItem>
      </MenuList>
    </Menu>
  )
}

export default MenuBar