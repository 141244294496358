import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';

const Thankyou = () => {
  return (
    <Flex 
      position={'fixed'} 
      top={0} 
      left={0} 
      right={0} 
      bottom={0} 
      bg={'rgba(255, 255, 255, 0.7)'} 
      justifyContent={'center'} 
      alignItems={'center'}
    >
      <Flex 
        w={'100%'} 
        maxW={'4xl'} 
        flexDirection={'column'} 
        alignItems={'center'} 
        py={12} 
        gap={6} 
        borderWidth={'2px'} 
        borderColor={'#BEEF8E'} 
        borderRadius={'8px'} 
        bg={'#fff'} 
        px={4}
      >
        <Heading as={'h1'} fontSize={{ base: '20px', md: '32px' }} textAlign={'center'}>Thank you for your order!</Heading>
        <Text textAlign={'center'}>We will get in touch with you shortly</Text>
      </Flex>
    </Flex>
  );
}

export default Thankyou;
