import React, { useEffect, useState } from 'react'
import Stepper from '../../components/Stepper/Stepper'
import Navbar from '../../components/Navbar/Navbar'
import Products from '../../components/ProductLayout/Products.js'
import Footer from '../../components/Footer/Footer'
import Details from '../../components/Details/Details'
import Checkout from '../../components/Checkout/Checkout'
import useProductStore from '../../zustand/useProductStore'
import useOrderStore from '../../zustand/useOrderStore'
import usePropertyDetailsStore from '../../zustand/usePropertyDetailsStore'

export const Home = () => {
  const resetProductDetails = useProductStore(state => state.resetProductDetails);
  const resetOrderDetails = useOrderStore(state => state.resetOrderDetails);
  const resetPropertyDetails = usePropertyDetailsStore(state => state.resetPropertyDetails);

  let [activeStep, setActiveStep] = useState(0);

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    resetProductDetails();
    resetOrderDetails();
    resetPropertyDetails();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <div>
      <Navbar />
      <Stepper activeStep={activeStep} setActiveStep={setActiveStep} handleNextStep={handleNextStep} />
      {
        activeStep === 0 && <Products handleNextStep={handleNextStep} />
      }
      {
        activeStep === 1 && <Details handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />
      }
      {
        activeStep === 2 && <Checkout setActiveStep={setActiveStep} handleReset={handleReset} handlePreviousStep={handlePreviousStep} />
      }
      <Footer />
    </div>
  )
}
