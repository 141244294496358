import { create } from "zustand";

// Define the initial state of the property details
const initialState = {
  address: "",
  propertyType: null,
  access: null,
  comments: "",
  dateTime: [],
  officeName: "",
  officeAddress: "",
  tenantName: "",
  tenantNumber: "",
  // porterEmail: "",
  boxCode: "",
};

// Define the store
const usePropertyDetailsStore = create((set) => ({
  propertyDetails: initialState,
  setPropertyDetails: (newDetails) =>
    set((state) => ({
      propertyDetails: { ...state.propertyDetails, ...newDetails },
    })),
  resetPropertyDetails: () => set({ propertyDetails: initialState }),
}));

export default usePropertyDetailsStore;
