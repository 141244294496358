import useDataStore from "../zustand/useDataStore";
const dataStore = useDataStore.getState();
export const getProductData = async () => {
  if (dataStore.products.length) {
    return dataStore.products;
  }
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/services`);
  const data = await response.json();
  dataStore.setProducts(data);
  return data;
};

export const getBundleData = async () => {
  if (dataStore.bundles.length) {
    return dataStore.bundles;
  }
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bundles`);
  const data = await response.json();
  dataStore.setBundles(data);
  return data;
};

export const getAddOnData = async () => {
  if (dataStore.addOns.length) {
    return dataStore.addOns;
  }
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/addons`);
  const data = await response.json();
  dataStore.setAddOns(data);
  return data;
};
